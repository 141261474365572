/*------------------------------------*\
    #MAIN ELEMENT
\*------------------------------------*/

/**
 * Main element
 */
[role=main] {
	display: block;
	padding: 1rem;
}
