/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/

/**
 * Layout Container
 * 1) Caps the width of the content to the maximum width
 *    and centers the container
 */
.l-container {
	max-width: $l-max-width;
	margin: 0 auto;
}

/**
 *
 * 1) This narrow layout container is for lists, forms,
 *    and other singular objects that aren't dashboard-y
 *    kinds of displays
 */
.l-linelength-container {
	margin: 0 auto;
    max-width: $l-linelength-width;
}





/*------------------------------------*\
    #GRID
\*------------------------------------*/

/**
 * Grid Container
 */
.l-grid {
	display: flex;
	flex-wrap: wrap;

	@supports (display: grid) {
		display: grid;
		grid-gap: 16px;
		grid-template-columns: repeat(auto-fill, minmax(285px, 1fr) );
		margin: 0;
	}
}

/**
 * Grid Item
 */
.l-grid__item {

}