/*------------------------------------*\
    #FORMS
\*------------------------------------*/

/**
 * 1) Form element base styles
 */

/**
 * Input placeholder text base styles
 */
::-webkit-input-placeholder {
   color: $color-gray-50;
}

::-moz-placeholder {
   color: $color-gray-50;
}

:-ms-input-placeholder {
   color: $color-gray-50;
}

/**
 * Fieldset base styles
 */
fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}

/**
 * Legend base styles
 */
legend {
    margin-bottom: 0.25rem;
}

/**
 * Label base styles
 */
label {
	display: block;
    padding-bottom: 0.25rem;
    color: $color-gray-93;
}

/**
 * Add font size 100% of form element and margin 0 to these elements
 */
button, input, select, textarea {
    font-family: inherit;
    font-size: $font-size-med;
    margin: 0;
}

/**
 * Text area base styles
 */
textarea {
	resize: none;
}

/**
 * Input  and text area base styles
 */
input, textarea {
    width: 100%;
    padding: 0.5rem;
    border: $border-thickness solid $color-gray-73;
    background: $color-white;

    &:focus {
		border-color: $color-gray-93;
	}
}
