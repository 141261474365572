.bg_shape {
  width: 100% !important;
  transform: skewY(-0.7deg) scale(1.3);
  overflow: hidden;

  .background_img {
    height: 140px;
    background-image: url(../../../../asset/images/tookone/legalInformation_bg.png) !important;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    // margin-top: 70px;
  }
}
.leagal-info-main > .leagal-info-shape{
  transform: skew(0deg) !important;
}
.background_img::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.legalinformation {
  z-index: 999;

  h1 {
    padding: 18px 0 !important;
    margin: 0;
    padding: 0;
    font-family:'Open Sans',sans-serif;
    font-weight: bold;


  }
}

.section-1 {
  margin-top: 70px !important;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  box-shadow: 0 0 24px #b2e1ffa1;
  padding: 0 20px;
  padding-top: 40px;
  height: 100%;

  .accordion:nth-child(1) {
    padding-top: 0px !important;
  }

  .accordion-item {
    outline: none !important;
    border: none !important;
    padding-top: 45px;
  }
  .accordion-item:nth-child(1) {
    padding-top: 0px !important;
  }

  .dropdown {
    font-size: 20px;
  }

  .Accordion_body {
    border-radius: 5px 5px 0 0;
    border-top: 5px solid #9bcc37;
    color: #b1b1b1;
    font-size: 22px;
    width: 100%;
    letter-spacing: 1px;
  }

  .accordion-item .accordion-button {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent;
  }
  .accordion-button::after {
    transform: rotate(-90deg);
  }
  .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg);
  }
}

.section-2 {
  margin-top: 20px !important;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  box-shadow: 0 0 28px #b2e1ffa1;
  padding-left: 60px !important;
  padding: 0 20px;
  padding-top: 40px;

  .contain {
    margin-bottom: 10px;

    h6 {
      position: relative;
    }

    h6::before {
      left: -15px;
      border-radius: 30px 0px 0px 30px;
      position: absolute;
      content: "";
      height: 78px;
      width: 5px;
      background-color: #9bcc37;
    }

    p {
      margin: 0;
      font-size: 15px !important;
    }
  }
}
