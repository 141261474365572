/*------------------------------------*\
    #LISTS
\*------------------------------------*/

/**
 * 1) List base styles
 */

 /**
  * Remove list styles from unordered and ordered lists
  */
ol, ul {
	list-style: none;
}
