* {
    margin: 0;
    padding: 0;
}

.main-header .nav {
    padding-left: 90px !important;
}

.admin-container {
    position: relative;
    padding-top:70px;
   

}

.mobile-icon {
    height: 42px;
    width: 28px;
    background-color: transparent;
    border: 5px solid #79A12A;
    display: block;
}

.admin-sidebar-container {
    height: 100vh;
    background-color: #010239;
    width: 15%;
    position:fixed;
    // position: ;
    top: 70px;
    left: 0;
    

    .border-bottom {
        border-bottom: 2px solid #fff;
        width: 100%;
    }
}
.header{
    position:fixed;
    top:70px;
    z-index:9999999;
}
.table-buttons
{
    width:100%;
    height:100%;
}
.buttons
{
    padding-top:120px;
    position:fixed;
    z-index:9999;
    padding-bottom:15px;
    background-color:transparent;
    @media (max-width: 991px) {
        width:97%;
    }
}
.registration-btn
{
    background-color:#fff;
}


.header_icon {
    h3 {
        color: #878787;
        cursor: pointer;
    }
}

.user-icon
{

        height:30px;
        width:30px;
        cursor: pointer;
}
.logo-text {
    padding-right: 10px !important;

    p {
        font-size: 13px;
        margin: 0;
        border-right: 5px solid #9BCC37;

    }
}

.table-fixed tbody {
    height: 300px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
    float: left;
    position: relative;

    &::after {
        content: '';
        clear: both;
        display: block;
    }
}
.table {
    padding-top:220px;
    @media (max-width: 767px) {
       padding-top:355px;
      }
    td {
        padding: 5px 0;
    }

    tbody {

        .table-row-1 {
        

            p {
                border-radius: 5px;
                text-align: center;
                width: 70px;
      
                
            }
            
        }

        .table-row-2 {
            .text {
                :nth-child(2) {
                    background-color: #878787;
                    color: #000;
                    text-align: center;
                    border-radius: 5px;
                    width: 70px;

                }
            }
        }
    }
    .status
    {
        // width:30px;
        display:flex;
        gap:30px;
    }
    .resend-button
    {
        padding:0px 15px;
        background-color:#010239;
        border:none;
        color:#fff;
        border-radius:5px   ;
    }
    .active-status{
        background-color: #9BCC37;
        color: #fff;
    }
    .inactive-status{
        background-color: #E3B620; 
        color: #fff;
    }
    .delete-status{
        background-color: #cf1d1d; 
        color: #fff;
    }
}
@media screen and(max-width:320px) {
    .logo {
      width: 50% !important;
    }
    .main-header {
      .nav {
          padding-left: 20px !important;
      }
    }
  }