/*------------------------------------*\
    #LINKS
\*------------------------------------*/

/**
 * Link base styles
 */
a {
	color: $color-gray-73;
	text-decoration: none;
	outline: 0;
	transition: color $anim-fade-quick $anim-ease;

	&:hover, &:focus {
		color: $color-gray-50;
	}

	&:active {
		color: $color-gray-93;
	}

	&:visited {
		color: $color-gray-93;
	}
}
