/*------------------------------------*\
    #CARD
\*------------------------------------*/

.c-card {
    border: 1px solid #808080;
    padding: 1rem;
}

.c-card--dark {
    background: #000;
    color: #fff;
}