/*------------------------------------*\
    #RESET
\*------------------------------------*/

/**
 * Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/
 */
* {
	box-sizing: border-box;
}

/**
 * 1) Zero out margins and padding for elements
 */
html, body, div, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, ol, ul, li, form, legend, label, table, header, footer, nav, section, figure {
	margin: 0;
	padding: 0;
}

/**
 * 1) Set HTML5 elements to display: block
 */
header, footer, nav, section, article, figure {
	display: block;
}
