/*------------------------------------*\
    #CARD LIST
\*------------------------------------*/

/**
 * 1) A collection of cards displayed as a list or grid 
 */
.c-card-list {
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
}