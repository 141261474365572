* {
    margin: 0;
    padding: 0;
}
body
{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
}

.main {
    background-color: #00012E;
    border-radius: 98px;
    border-bottom-left-radius: 0px;
    @media (max-width:991px) {
        border-radius: 52px;
    }
    .image-section {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .login-bg {

        width: 100%;

        .main-logo {
            top: 30%;
        }

        h1 {
            top: 65%;
            left: 15%;

            span {
                color: #9BCC37;
            }
        }

    }

    .form-group {
        padding-left: 50px;
        padding-right: 10px;


        .pass_icon {
            top: 0;
            bottom: 0;
            padding: 18px;
        }

        .pass_icon {
            color: #fff;
            font-size: 20px;
        }

        .input {

            input {
                border-radius: 70px;
                padding-left: 60px !important;
                border-bottom-left-radius: 0;
                color: #fff;
                font-family: 'Open Sans', sans-serif;

            }

            *::-webkit-input-placeholder {
                color: #fff !important;
            }

            *:-moz-placeholder {
                /* FF 4-18 */
                color: #fff !important;
                opacity: 1;
            }

            *::-moz-placeholder {
                /* FF 19+ */
                color: #fff !important;
                opacity: 1;
            }

            *:-ms-input-placeholder {
                /* IE 10+ */
                color: #fff !important;
            }

            *::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #fff !important;
            }

            *::placeholder {
                /* modern browser */
                color: #fff !important;
            }

            :focus {
                border: 0;
                outline: 0;
                // border-radius: 70px;
                // border-bottom-left-radius:0;
            }

            .button {
                width: 100%;
                border: 0;
                font-weight: bold;
                font-size: 20px;
                color: #fff;
                border-radius: 70px;
                border-bottom-left-radius: 0;
                background-color: #9BCC37;
            }
        }
        .otp 
        {
            a
            {

                color: #fff;
                text-decoration: none;
            }
        }
    }
    .dropdown{
        .dropdown-toggle{
            border-radius: 50px;
            border-bottom-left-radius: 0px !important;
        }
    }
}

@media only screen and (max-width: 320px) {


    .main {
        margin-top: 15px !important;
        padding-right: 5px !important;
        border-radius: 45px !important;


        .login-bg {

            width: 100%;
        }

        h1 {
            top: 65%;
            left: 0% !important;
        }

        .form-group {
            padding-top: 0 !important;
            padding-bottom: 30px;
            padding-left: 5px !important;
            padding-right: 0px !important;
        }
    }

}

@media (min-width: 320.1px) and (max-width: 768px) {

    .main {
        margin-top: 180px !important;
        padding-right: 5px;
        border-radius: 90px;
        border-bottom-left-radius: 65px;
        border-bottom-right-radius: 65px;


        .form-group {
            padding-top: 0 !important;
            padding-bottom: 30px;
            padding-left: 5px !important;
            padding-right: 0px !important;
        }
        .main-logo {
            width: 100% !important;
        }
        
    }

}