/*------------------------------------*\
    #TABLES
\*------------------------------------*/

/**
 * Table
 */
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    thead{
        tr{
            th{
                padding-left: 10px;
                padding-right: 15px;
            }
        }
    }
    tbody{
        tr{
            td{
                padding-left: 10px !important;
                padding-right: 15px !important; 
            }
        }
    }
}
.table{
    white-space: nowrap;
    overflow: auto;
}

/**
 * Table header cell
 */
th {
    text-align: left;
}

/**
 * Table row
 */
tr {
    vertical-align: top;
}
