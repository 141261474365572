/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

/**
 * Button and submit inputs reset
 * 1) These should be styled using c-btn
 */
button {
	cursor: pointer;
}
