.page {
  width: 100%;
  overflow: hidden;
}
.bg_shape {
  width: 100%;
  transform: skew(-20deg) scale(1.02) !important;
}
.game-header{
  margin-top: 70px;
}
.background_img {
  overflow: hidden;
  height: 150px;
  width: 100%;
 
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: -99;
  overflow: hidden;
}
.gamemanual_header{
  background-image: url(../../../../asset/images/game/game-header-bg.png) !important;
}
.background_img::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: -9;
  overflow: hidden;
}
.legalinformation {
  z-index: 999;
}

.section-1 {
  margin-top: 70px !important;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  box-shadow: 0 0 24px #b2e1ffa1;
  padding: 0 20px;
  padding-top: 40px;
  height: 100%;

  .accordion:nth-child(1) {
    padding-top: 0px !important;
  }

  .accordion-item {
    outline: none !important;
    border: none !important;
    padding-top: 45px;
  }
  .accordion-item:nth-child(1) {
    padding-top: 0px !important;
  }

  .dropdown {
    font-size: 20px;
  }

  .Accordion_body {
    border-radius: 5px 5px 0 0;
    border-top: 5px solid #9bcc37;
    color: #b1b1b1;
    font-size: 22px;
    width: 100%;
    letter-spacing: 1px;
  }

  .accordion-item .accordion-button {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent;
  }
  .accordion-button::after {
    transform: rotate(-90deg);
  }
  .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg);
  }
}

.section-2 {
  margin-top: 70px !important;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  box-shadow: 0 0 28px #b2e1ffa1;
  padding-left: 60px !important;
  padding: 0 20px;
  padding-top: 40px;

  .contain {
    margin-bottom: 10px;

    h6 {
      position: relative;
    }

    h6::before {
      left: -15px;
      border-radius: 30px 0px 0px 30px;
      position: absolute;
      content: "";
      height: 78px;
      width: 5px;
      background-color: #9bcc37;
    }

    p {
      margin: 0;
      font-size: 15px !important;
    }
  }
  .game-card {
    gap: 40px;
    display: flex;

    img {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 1050px) {
  .game-card {
    display: block !important;

    :nth-last-child(n) {
      padding-top: 30px !important;
    }
  }
}
