/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

/**
 * Heading 1 base styles
 */
h1 {
	font-size: 2.25rem;
	line-height: 1;
}

/**
 * Heading 2 base styles
 */
h2 {
	font-size: 1.6rem;
	line-height: 1.2;
	letter-spacing: -0.02rem;
	font-weight: normal;
	margin-bottom: 0.5rem;
}

/**
 * Heading 3 base styles
 */
h3 {
	font-size: 1.2rem;
	line-height: 1.2;
	margin-bottom: 0.3rem;
}

/**
 * Heading 4 base styles
 */
h4 {
	font-size: 1rem;
	line-height: 1.2;
	margin-bottom: 0.3rem;
}
