/*------------------------------------*\
    #LOGO
\*------------------------------------*/

/**
 * Branding image or text of the site
 */
.c-logo {
    display: inline;
}

/**
 * Logo image 
 */
.c-logo__img {
    display: block;
}
