.tookonefooter {

    height: auto;
    width: 100%;
    background-color: #0E012B;
    position: sticky;
    bottom: 0;
    left: 0;

    .tookonefooter-text {

        max-width: 1440px;
        margin: 0px auto;

        p {
            margin-bottom:0;

            @media only screen and (max-width: 767px) {
                padding-top: 20px;
        }
    }

        ul {
            padding: 0;
            margin: 0;

            li {
                a {
                    display: block;
                    padding: 18px 20px;

                    span {
                        display: block;
                        padding-left: 20px;
                    }
                }

            }
        }
    }
}
