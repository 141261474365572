* {
  margin: 0;
  padding: 0;
}

.main-header {
  height: 70px;
  width: 100%;
  background-color: #010239;
  position:fixed;
  top:0px;
  z-index:999;

  .logo {
    width: 10%;
  }

  .nav {
    width: 50%;
    padding-left: 64px;
    margin-right: auto;
    input {
      padding-left: 16px;
    }

    .search {
      border: 0;
      outline: 0;
      border-bottom: 2px solid green;
      color: #fff;
      width: 80%;
    }

    *::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.815) !important;
    }

    *:-moz-placeholder {
      color: rgba(255, 255, 255, 0.815) !important;
      opacity: 1;
    }

    *::-moz-placeholder {
      color: rgba(255, 255, 255, 0.815) !important;
      opacity: 1;
    }

    *:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.815) !important;
    }

    *::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.815) !important;
    }

    *::placeholder {
      color: rgba(255, 255, 255, 0.815) !important;
    }

    :focus {
      border: 0;
      outline: 0;
      border-bottom: 2px solid green;
    }
  }
  .header_dropdown {
    &:focus {
      border: 0;
      outline: none;
      box-shadow: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
  }
  .dropdown-section {
    border-left: 1px solid #fff;

    .dropdown {
      border: 0;
      outline: 0;
    }
  }
}

@media screen and(max-width: 768px) {
  .logo {
    width: 30% !important;
  }
  .nav {
    padding-left: 10px !important;

    input {
      padding-left: 0 !important;
    }
  }
}
@media screen and(max-width:320px) {
  .logo {
    width: 50% !important;
  }
  .main-header {
    .nav {
        padding-left: 0 !important;
    }
  }
}
