/*------------------------------------*\
    #PAGE HEADER
\*------------------------------------*/

/**
 * 1) Container that consists of of a page header title and description
 */

/**
 * Page header title
 */
.c-page-header__title {
	margin-bottom: 1rem;
}

/**
 * Page description
 */
.c-page-header__desc {
    margin-bottom: 2rem;
}
