* {
  margin: 0;
  padding: 0;
}

.admin-container {
  width: 85%;
  margin-left: auto;
}

.header {
  height: 120px;
  width: 100%;
  background-color: #9bcc37;

  .header-icon {
    .icons
    {
      width:369px;
      @media (max-width: 500px) {
        width:auto;
      }
    }
    .Cloudkicon {
      position: relative;

      h3 {
        position: absolute;
        top: 45%;
        left: 8px;
        margin: 0;
        font-size: 20px;
      }
    }

    img {
      width: 60px;
    }
  }

  .profile-ico {
    p {
      margin: 0 !important;
      font-weight: bold;
      font-size: 20px;
      margin-left: 10px !important;
    }
    &.contect-req-title {
      width: 200px;
      img {
        width: 25px;
      }
    }
  }
}

.buttons {
  margin: 5px 0 !important;
  font-size: 18px;
  font-weight: bold;
  width:84%;


  .first-button {
    border-radius: 30px;
    border-bottom-left-radius: 0;
    text-align: center;
    padding: 10px 15px 10px 10px;
    font-size: 16px !important;
    font-weight: bold;
    background-color: #9bcc37;
    color: #fff;
    height: 65px;
  }

  .second-button {
    border-radius: 30px;
    border-bottom-left-radius: 0;
    padding: 10px 15px 10px 10px;
    height: 65px;
    text-align: center;
    background-color: #e3b620;
    color: #fff;
  }

  .thread-button {
    border-radius: 30px;
    border-bottom-left-radius: 0;
    padding: 10px 15px 10px 10px;
    height: 65px;
    text-align: center !important;
    background-color: #cf1d1d;
    color: #fff;
  }
}
.requestbuttons
{
  background-color:#e2e2e2 !important;
}
.cursor-pointer
{
  cursor: pointer;
}

.game_from {
  background-color: #e2e2e2;
  height: 100%;
  border-radius: 3px;
  margin-bottom: 10px;
  padding-top:210px;
  @media (max-width: 991px) {
    margin-bottom: 16px;
  }
  @media (max-width: 768px) {

    padding-top:350px;
  }
  .game-text {
    text-align: center;
    margin: 0;
  }

  p {
    color: #000039;
    font-size: 16px;
    margin: 0;
  }

  h3 {
    background-color: #1d89cf;
    color: #fff;
    font-size: small;
    padding: 5px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
  }

  .small-button {
    .btn-1 {
      margin-right: 2px;
      background-color: #fff;
      padding: 10px !important;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 0;

      span {
        display: block;
        padding: 7px !important;
        border-radius: 99999px;
        background-color: #9bcc37;
      }
    }
    .btn-3 {
      margin-right: 2px;
      background-color: #fff;
      padding: 10px !important;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 0;

      span {
        display: block;
        padding: 7px !important;
        border-radius: 99999px;
        background-color: #e3b620;
      }
    }
    .btn-4 {
      margin-right: 2px;
      background-color: #fff;
      padding: 10px !important;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 0;

      span {
        display: block;
        padding: 7px !important;
        border-radius: 99999px;
        background-color: #cf1d1d;
      }
    }

    .btn-2 {
      margin-left: 2px;
      background-color: #fff;
      border-radius: 10px;
      padding: 5px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 0;

      span {
        padding: 1px;
        display: block;
      }
    }
  }

  .data-input {
    margin-top: 10px;
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    border-bottom-left-radius: 0;

    p {
      margin-bottom: 0;
    }
  }

  .logo-section {
    background-color: #fff;
    padding: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    border-radius: 15px;
    border-bottom-left-radius: 0;

    .logo {
      height: 30px;
      width: 30px !important;
      border: 2px solid gray;
      border-radius: 999999px;
      background-color: #ac1d1d;

      span {
        display: inline-block;
        font-size: 23px;
        transform: scale(1, 1.3);
        font-weight: bold;
        color: #fff;
      }
    }

    .text {
      width: calc(100% - 20px);
      margin-left: 10px;

      p {
        font-size: 10px;
      }

      h5 {
        margin: 0;
        font-size: 14px;
      }
    }
  }

  .section-2 {
    padding: 6px;
    box-shadow: none;
    margin-top: 10px !important;
    padding-left: 0 !important;
  }

  .text-section {
    margin: 0;
  }
  .option
  {
    top: 100%;
    left: 0;
    background-color: #fff;
    width: auto;
    padding: 10px;
    border-radius: 15px;
    border-top-left-radius: 0;
    z-index: 99;
  }
}
.notice-input{width: 75%;
border-radius: 8px 8px 8px 0px;
padding: 2px 9px;
}
.notice-input-btn{
  padding: 2px 8px !important;
  margin-left:5px ;
}
@media screen and(max-width: 991px) {
  .admin-container {
    width: 100%;
  }

  .logo-section {
    margin-left: 0 !important;
  }

  .first-button {
    width: 100% !important;
    margin-bottom: 5px;
    img {
      width: 63px;
      margin-right: 0px !important;
    }
  }

  .second-button {
    width: 100% !important;
    margin-bottom: 5px;
    img {
      width: 50px;
      margin-right: 0px !important;
    }
  }

  .thread-button {
    width: 100% !important;
    margin-bottom: 5px;
    img {
      height: 40px;
      width: 50px;
      margin-right: 0px !important;
    }
  }

  .small-button {
    padding: 10px 12px !important;
  }
  .btn-1 {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .btn-2 {
    width: 100% !important;
  }

  .menu-bar {
    font-size: 30px;
  }
}
