.kickstarter{
    background: linear-gradient(170deg, rgba(4,0,69,1) 0%, rgba(28,28,32,1) 84%);
    .lang-main{
       background-color: #00002c; 
       border-radius: 30px 30px 30px 0 ;
       position: relative;
       padding: 0 50px;

       .flag_main {
        margin-top: -30px;
       }
       .star{
        z-index: 100;
     
        img{
            width: 150px;
            position: absolute;
            left: -60px;
            top: -50px;
            @media (max-width:425px){
                width: 120px;                      
            }
        }
       }
       h2{
        font-weight: 600;
        margin-top: 20px;
        }
       .flag_img{
        img{
            width: 80px;
        }
    }
    }
    .hip_hard{
        background-color: #fff;
        border-radius: 30px 30px 30px 0 ;
        padding: 20px;
        height: 100%;
        .book_img{
            text-align: center;
            img{
                width: 75px;
                margin: auto;
                border: 2px solid rgba(0, 0, 0, 0.5);
                border-radius: 50px 50px 50px 0;
            }
        }
        p{
            margin: auto;
            max-width: 500px;
            font-size: 29px;
            text-align: center;
            color: #05104a;
            font-weight: 500;
            @media (max-width:991px){
                font-size: 22px;
            }
        }
    }
    .game{
        background-color: #8bc34a;
        border-radius: 30px 30px 30px 0 ;
        padding: 30px;
        box-sizing: border-box;
        height: 100%;
        .book_img{
            text-align: center;
            img{
                width: 50px;
                margin: auto;
            }
        }
        .blue{
            text-align: center;
            position:relative;
            img:first-child{
               border: 2px solid red;
               border-radius: 50px 50px 50px 0;
               width: 80px;
               height: 80px;
            }
            img:nth-child(2){
                width: 120px;
                margin-left: auto;
                position: absolute;
                bottom: -100%;
                right:-30px;
                width: 90px;
                @media (max-width:991px){
                    width: 65px;
                    right: -30px;
                }
            }
        }
        p{
            margin: auto;
            max-width: 500px;         
            font-size: 28px;
            color: #05104a;
            font-weight: 500;
            padding-bottom: 30px;
            box-sizing: border-box;
            @media (max-width:991px){
                font-size: 22px;
            }
        }
    }
    .original{
        padding: 27px 100px 0;
        text-align: center;
        @media (max-width:1400px){
            padding: 27px 50px;
        }
        @media (max-width:1400px){
            padding:  20px;
        }
        @media (max-width:575px){
            padding:  0px;
        }
        h5{
            text-align: center;
            color: #fff;
            font-size: 37px;
            font-weight: 600;
            margin-bottom: 10px;
            @media (max-width:991px){
                font-size: 25px;
            }
            @media (max-width:575px){
                font-size: 20px;
                margin-top: 20px;
            }
        }
        .all_image{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 200px;
            @media (max-width:1400px){
                padding-left: 50px;
            }
         
            .image_file{
                display: flex;
                align-items: center;
                @media (max-width:575px){
                    padding-left: 20px;
                }
                @media (max-width:425px){
                    padding-left: 0px;
                }
                img:nth-child(1){
                    width: 261px;
                    @media (max-width:991px){
                        width: 250px;
                    }
                    @media (max-width:575px){
                        width: 140px;
                    }
                }
                img:nth-child(2){
                    width: 180px;
                    margin-left: -120px !important;
                    @media (max-width:575px){
                        width: 80px;
                        margin-left: -70px !important;
                    }
                }
            }
            .image_cartoon{
                width: 120px;
                margin-left: 100px;
                transform: rotate(15deg);
                @media (max-width:991px){
                    width: 90px;
                }
                @media (max-width:575px){
              width: 50px;
                }
            }
        }
        .game_manual{
            display: flex;
            align-items: end;
            justify-content: space-between;
            margin-top: -110px;
            @media (max-width:575px){
                margin-top: -50px;
                padding: 0 10px;
            }
            img{
                width: 250px;
                @media (max-width:575px){
                    width: 100px;
                }
            }
            button{
                border-radius: 15px 20px 20px 0;
                padding:8px 40px;
                background-color: #fff;
                font-size: 25px;
                border: none;
                color: #05104a;
                font-weight: 500;
                margin-bottom: 50px;
                @media (max-width:1400px){
                    padding: 6px 25px;
                }
                @media (max-width:1200px){
                    padding: 6px 10px;
                    font-size: 20px;
                }
                @media (max-width:991px){
                    padding: 6px 10px;
                    font-size: 20px;
                }
                @media (max-width:575px){
                    padding: 6px 5px;
                    font-size: 16px;
                }
                @media (max-width:425px){
                    padding: 6px 5px;
                    font-size: 10px;
                }
            }
        }
        .king{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width:575px){
                margin-bottom: 20px;
            }
            h4{
                margin: 0 60px;
                color: #fff;
                font-size: 20px;
                @media (max-width:575px){
                    margin: 0;
                    font-size: 16px;
                }
            }
            img{
                width: 80px;
                @media (max-width:575px){
                    width: 50px;
                }
            }
        }
    }
    .color_splash{
        background-color: gray;
        height: 100%;
        padding: 30px 0;
        box-sizing: border-box;
        border-radius: 30px 30px 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        h5{
            text-align: center;
            color: #fff;
            font-size: 35px;
            font-weight: 600;
            margin-bottom: 30px;
            @media (max-width:1400px){
                font-size: 25px;
            }
            @media (max-width:575px){
                font-size: 20px;
            }
        }
        .image_section{
            display: flex;
            align-items: end;
            justify-content:center;
            position: relative;
            &::before{
                position: absolute;
                content: "";
                top: 35%;
                left: 52%;
                width: 15px;
                height: 100px;
                z-index: 100;
                border-radius: 10px;
                background-color: black;
                transform: translate(-35% -50%);
                @media (max-width:1200px){
                    top: 31%;
                }
                @media (max-width:991px){
                    top: 34%;
                    height: 80px;
                }
                @media (max-width:900px){
                    left: 69%;
                }
                @media (max-width:575px){
                    left: 62%;
                }
            }
            &::after{
                position: absolute;
                content: "";
                top: 50%;
                left: 55%;
                width: 100px;
                height: 15px;
                z-index: 100;
                border-radius: 10px;
                background-color: black;
                transform: translate(-55%);
                @media (max-width:991px){
                    width: 80px;
                }
                @media (max-width:900px){
                    left: 72%;
                }
                @media (max-width:575px){
                    left: 66%;
                  }
            }
            img{
                width: 200px;
                margin-right: 20px;
                @media (max-width:1200px){
                    width: 150px;
                }

            }
            .cartton_img{
                position: relative;
                @media (max-width:900px){
                    margin-left: -117px;
                }
                @media (max-width:575px){
                    margin-left: -37px;
                }
                img:first-child{
                    width: 150px;
                    @media (max-width:1200px){
                        width: 110px;
                    }
                }
                img:last-child{
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 50px;
                    transform: translatex(-50%) rotate(15deg);
                }
            }
        }
        .star_image{
            margin-bottom: -70px;
            @media (max-width:991px){
                margin-bottom: -90px;
            }
            img{
                width: 150px;
            }
        }
    }
}
.border-round{
    border-radius: 98px;
    border-bottom-left-radius: 0px;
}
