/*------------------------------------*\
    $FOOTER
\*------------------------------------*/

/**
 * 1) Global footer at the bottom of each page that contains a navigation and other information.
 */
.c-footer {
    background: #333;
    color: #fff;
    padding: 2rem;
}

.c-footer__copyright {
    margin-top: 2rem;
    text-align: center;
}