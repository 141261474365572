/*------------------------------------*\
    #MEDIA
\*------------------------------------*/

/**
 * Responsive image styling
 * 1) Allows for images to flex with varying screen size
 */
img {
	max-width: 100%;
	height: auto;
}
