/*------------------------------------*\
    #HEADER
\*------------------------------------*/

/**
 * Global block at the top of each page containing the navigation, logo, and other potential utility nav
 */
.c-header {
    background: #ddd;
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    gap: 10px;
}