.contect_header
{
    background-image: url(../../../../asset/images/tookone/form_bg.png)!important;
    height: 150px;
    // margin-top: 70px;
}
.background_img
{
    padding-top: 22px !important;
}
.contact-section
{
    .text-section
    {
        h5
        {
            color: #160043;
            font-weight: 400;
            padding-top: 20px;
            padding-bottom: 15px
        }
        span
        {
            font-weight: 900;
        }
        p
        {
            color: #7B7B7B;
            text-align: right;
        }
    }
    .contact_info
    {
        margin-top: 150px;
        font-size: 22px;
        color: #AED94B;
        P
        {
            font-size: 22px;
            margin-bottom: 10px !important;
            margin-left: 20px !important;
            text-align: left;
            margin: 0;
        }
    }
}
.form-bg
{
    height: 100%;
    width: 100%;
    background-image: url(../../../../asset/images/tookone/form_bg.png);
    background-position:top center;
    background-size: cover;
    overflow: hidden;

    .contact_form
    {
        padding-top: 110px;
        padding-bottom: 0;
        padding-left: 70px;
        input
        {
            background-color: #fff;
            padding: 20px;
            border-radius: 15px;
            margin-bottom: 30px;
        }
        input::placeholder {
            font: 20px/3 sans-serif;
        }
        textarea
        {
            background-color: #fff;
            padding: 20px;
            padding-bottom: 80px;
            border-radius: 15px;
        }
        textarea::placeholder {
            font: 20px sans-serif;
        }
        button
        {
            margin-top: 30px;
            width: 100%;
            background-color: #AED94B;
            color: #fff;
            font-weight: bold;
            padding: 18px;
            border-radius: 15px;
            margin-bottom: 20px;
            border: 0;
            font-size: 22px;
        }
    }
    .contact_text
    {
        padding: 110px 0;
       

        h1
        {
            margin: 0 !important;
        }
        p
        {
            margin-top: 20px;
            margin-bottom: 30px;
            font-size: 18px;
        }
        span
        {
            font-size: 18px;
        }
    }
    
    .box-icon
    {
        height: 65px;
        width: 68px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 768px) {

    .contact_form
    {
        padding-left: 12px !important;
    }
    .contact_text
    {
        padding-left: 0 !important;
        padding-bottom: 0 !important;
    }
}