.side-bar {
    color: #7B7B7B;
    background-color: #FFFFFF;
    box-shadow: -20px 3px 37px #00000029;

    .title
    {
        font-size: 17px;
        margin: 0 !important;
    }
}

.link {
    p {
        background: #E9ECEF;
   
        border-radius: 15px 15px 15px 0;
        width: 100%;
        position: relative;
        /* height: 35px; */
        padding: 5px 74px 5px 10px !important;
        color: #7B7B7B;

        button {
            padding: 5px 20px;
            border: none;
            border-radius: 20px 20px 20px 0;
            background-color: #99CC33;
            color: #fff;
            margin-left: 97px !important;
            position: absolute;
            top: 0;
            right: 0;
            
            
        }
    }
}

.social-icon {
    .icon {
        height: 50px !important;
        width: 50px !important;
        border-radius: 9999px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        button{
            
            svg{
                width: 55px;
                height: 55px;
                @media screen and (max-width:575px) {
                    width: 35px;
                height: 35px;
                }
            }
        }
    }
}

.offcanvas.offcanvas-start {
    border-radius: 37px 0 0 0;
    top: 0;
    right: 0;
}
.offcanvas-backdrop
{
    background-color: #FFFFFF !important;
    opacity: 0.8 !important;
    filter: blur(38),brightness(34);
}