.round-radius {
  border-radius: 80px;
  border-bottom-left-radius: 0;
}

.container-xxl {
  max-width: 100% !important;
}
.share_icon {
  width: 30px;
  fill: #fff;
  top: 24px;
  left: 49px;
}
.index {
  // height: 900px;
  width: 100%;
  align-items: center;
  background-color: rgba(16, 5, 77, 1);

  .background {
    background-image: url(../../../../asset/images/tookone/background_big_size.png);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    background-position: bottom;

    .indexlogo {
      padding-left: 30px;
      padding-top: 126px;

      h1 {
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        font-size: 80px;
        padding-top: 70px;
        @media (max-width: 768px) {
          font-size: 42px;
        }
      }

      p {
        font-size: 46px;
        font-weight: bold;
        padding-top: 70px;
        @media (max-width: 768px) {
          font-size: 30px;
          padding-top: 0px;
        }
        span {
          font-weight: normal;
          color: #99cc33;
        }
      }
    }
  }

  .pre-register {
    // max-width: 514px;
    height: 500px;
    padding: 30px 15px;
    background-color: #707070;
    border-radius: 55px 55px 55px 0;
    margin-left: auto;

    h4 {
      font-size: 40px !important;
      line-height: 42px;
      font-family: "Open Sans" sans-serif;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 30px !important;
        line-height: 32px;
      }
    }

    .icon {
      top: 17px;
      left: 12px;
    }

    p {
      font-size: 11px !important;
      margin: 0 !important;
      margin-left: 9px !important;

      span {
        font-weight: bold;
        color: #99cc33;
      }
    }

    input {
      border-radius: 61px 61px 61px 0;
      padding-left: 40px !important;
      color: #FFF;
    }

    input:focus {
      border: none;
      outline: none;
    }

    *::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.866) !important;
    }

    *:-moz-placeholder {
      /* FF 4-18 */
      color: rgba(255, 255, 255, 0.866) !important;
      opacity: 1;
    }

    *::-moz-placeholder {
      /* FF 19+ */
      color: rgba(255, 255, 255, 0.866) !important;
      opacity: 1;
    }

    *:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(255, 255, 255, 0.866) !important;
    }

    *::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.866) !important;
    }

    *::placeholder {
      /* modern browser */
      color: rgba(255, 255, 255, 0.866) !important;
    }

    input::placeholder {
      font-family: "Open Sans" sans-serif;
      font: 30px sans-serif;
    }

    .form-switch {
      input {
        width: 40px !important;
        padding: 9px 0 !important;
        border-radius: 999px !important;
        box-shadow: none;
        border: none;
        outline: none;
      }

      .form-check-input:checked {
        box-shadow: none;
        border: none;
        outline: none;
        background-color: #cbcbcb;
      }
    }

    button {
      background-color: #99cc33;
      border: none;
      padding: 15px;
      color: #fff;
      border-radius: 37px 37px 37px 0;
      margin-top: 15px;
    }
  }

  .Recommend {
    // max-width: 514px;
    // height: 500px;
    padding: 30px 15px;
    background-color: #fff;
    padding-bottom: 10px !important;
    color: #00002e;
    border-radius: 55px 55px 0px 0;
    margin-left: auto;

    h4 {
      font-size: 46px !important;
      line-height: 68px;
      font-family: "Open Sans" sans-serif;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 32px !important;
        line-height: 40px;
      }
    }

    h3 {
      margin-top: 70px;
      margin-bottom: 0;
      font-size: 46px !important;
      line-height: 68px;
      font-family: "Open Sans" sans-serif;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 32px !important;
        line-height: 40px;
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }

    button {
      text-align: center;
      background-color: #707070;
      border: none;
      padding: 20px 15px;
      color: #fff;
      border-radius: 37px 37px 37px 0;
      margin-top: 15px;
    }
  }
}

.product-tooken {
  width: 100%;
  background: #001120;
  .product {
    height: 100%;
    width: 100%;
    padding-top: 15px;
    border-radius: 55px 55px 55px 0;
    background-color: #99cc33;

    h1 {
      color: #00002e;
      margin: 0;
      font-size: 34px;
      font-weight: bold;
      padding-top: 0px;
    }

    .text {
      p {
        font-weight: 500;
        font-size: 32px !important;
        color: #00002e !important;
      }

      h2 {
        font-weight: bold;
        color: #00002e;
      }
    }
  }
}
.product-img{
  padding-left: 25px !important;
}

.icons_box {
  background-color: #fff;
  padding: 10px 20px;
  height: 100%;
  border-start-start-radius: 50px;
  border-bottom-right-radius: 50px;
  .part01 {
    display: flex;
    @media (max-width: 575px) {
      flex-wrap: wrap;
    }
    .icons_image {
      width: 300px;
      &:nth-child(2) {
        margin-top: 20px !important;
      }
      @media (max-width: 575px) {
        width: 50%;
        margin: auto;
        text-align: center;
      }
      img {
        width: 80px;
        @media (max-width: 1200px) {
          width: 50px;
        }
        @media (max-width: 425px) {
          width: 30px;
        }
      }
      span {
        display: block;
        font-size: 20px;
        font-weight: 600;
        @media (max-width: 767px) {
          font-size: 16px;
        }
        @media (max-width: 425px) {
          font-size: 14px;
        }
      }
    }
  }
  .part02 {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1200px) {
      display: block;
    }
    h2 {
      text-align: end;
      font-weight: 600;
      font-size: 40px;
      margin: 20px 0;
      color: rgb(6, 33, 64);
      @media (max-width: 1600px) {
        font-size: 30px;
      }
      @media (max-width: 991px) {
        text-align: center;
        margin-bottom: 0;
      }
      @media (max-width: 575px) {
        font-size: 20px;
      }
    }
    .daimod {
      display: flex;
      .icons_image {
        width: 300px;
        text-align: center;
        margin: auto;
        @media (max-width: 1800px) {
          width: 270px;
        }
        @media (max-width: 1600px) {
          width: 230px;
        }
        @media (max-width: 1400px) {
          width: 200px;
        }
        @media (max-width425px) {
          width: auto;
        }
        img {
          width: 80px;
          @media (max-width: 767px) {
            width: 50px;
          }
          @media (max-width: 425px) {
            width: 30px;
          }
        }
        span {
          display: block;
          font-size: 20px;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 16px;
          }
          @media (max-width: 425px) {
            font-size: 14px;
          }
        }
      }
    }
    .icons_image {
      width: 200px;
      text-align: end !important;
      @media (max-width: 1400px) {
        width: 100%;
        text-align: center !important;
      }
      img {
        width: 100px;
        @media (max-width: 1600px) {
          width: 80px;
        }
        @media (max-width: 1400px) {
          width: 60px;
        }
        @media (max-width: 767px) {
          width: 50px;
          margin-top: 20px;
        }
        @media (max-width: 425px) {
          width: 30px;
        }
      }
      span {
        display: block;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  // .index {
  //   height: 1039px !important;
  // }
  // .pre-register {
  //   height: 500px !important;
  // }
  // .Recommend {
  //   margin-right: auto !important;
  //   margin-top: 20px;
  //   height: 519px !important;
  // }
}

@media only screen and (max-width: 1280px) {
  // .index {
  //   height: 1100px !important;
  // }

  .background {
    .indexlogo {
      padding-top: 270px !important;
    }
  }
  // .pre-register {
  //   height: 550px !important;
  // }
  // .Recommend {
  //   margin-right: auto !important;

  //   height: 530px !important;
  // }
}
@media screen and (max-width: 1200px) {
  // .index {
  //   height: 1213px !important;
  // }
  // .pre-register {
  //   height: 606px !important;
  // }
  // .Recommend {
  //   margin-right: auto !important;

  //   height: 587px !important;
  // }
}
@media screen and(max-width:1100px) {
  // .pre-register {
  //   height: 660px !important;
  // }
  // .index {
  //   height: 1267px !important;
  // }
  // .Recommend {
  //   height: 587px !important;
  // }
}
@media screen and(max-width:1000px) {
  // .indexlogo {
  //   padding-left: 70px !important;
  // }
  // .pre-register {
  //   height: 660px !important;
  // }
  // .index {
  //   height: 1336px !important;
  // }
  // .Recommend {
  //   height: 656px !important;
  // }
}
@media screen and(max-width:950px) {
  // .indexlogo {
  //   padding-left: 35px !important;
  // }
  // .pre-register {
  //   height: 660px !important;
  // }
  // .index {
  //   height: 1335px !important;
  // }
  // .Recommend {
  //   height: 655px !important;
  // }
}

@media screen and(max-width:900px) {
  .logo-section {
    width: 100% !important;
  }
  .tooken-section {
    width: 100% !important;
  }

  .index .background .indexlogo {
    padding: 0 !important;
    padding-top: 73px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  img {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .index {
    height: auto !important;
  }

  h1 {
    padding-left: 0 !important;
    // text-align: center;
  }
  p {
    padding-left: 0 !important;
    // text-align: center;
  }
  .pre-register {
    height: auto !important;
    margin-right: auto !important;
    width: auto !important;
  }
  .Recommend {
    margin-right: auto !important;
    margin-top: 20px;
    height: auto !important;
    width: auto !important;
  }
  .product-tooken {
    padding-top: 20px;
    .product {
      h1 {
        font-size: 23px;
      }
      .product-text {
        p {
          text-align: center !important;
          font-size: 37px !important;
          margin-bottom: 12px;
        }
        h2 {
          text-align: center;
          font-size: 40px !important;
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {

  .product-tooken .product .product-text p {
    font-size: 30px !important;
  }
  .product-tooken .product h1
  {
    padding-top: 10px !important;
  }
}
