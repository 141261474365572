/*------------------------------------*\
    #ICON
\*------------------------------------*/

/**
 * 1) Small image that represents functionality
 */
.c-icon {
	height: 16px;
	width: 16px;
}
